import React, { useState } from 'react';
import axios from 'axios';
import { FaCloudUploadAlt } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import Select from 'react-select';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// Example list of plant types
const plantTypes = [
  { value: 'apple', label: 'Apple' },
  { value: 'apricot', label: 'Apricot' },
  { value: 'asparagus', label: 'Asparagus' },
  { value: 'aubergine', label: 'Aubergine' },
  { value: 'avocado', label: 'Avocado' },
  { value: 'banana', label: 'Banana' },
  { value: 'beetroot', label: 'Beetroot' },
  { value: 'broccoli', label: 'Broccoli' },
  { value: 'clementine', label: 'Clementine' },
  { value: 'corn', label: 'Corn' },
  { value: 'courgette', label: 'Courgette' },
  { value: 'fig', label: 'Fig' },
  { value: 'garlic', label: 'Garlic' },
  { value: 'grape', label: 'Grape' },
  { value: 'guava', label: 'Guava' },
  { value: 'jackfruit', label: 'Jackfruit' },
  { value: 'lemon', label: 'Lemon' },
  { value: 'mango', label: 'Mango' },
  { value: 'nectarine', label: 'Nectarine' },
  { value: 'olive', label: 'Olive' },
  { value: 'orange', label: 'Orange' },
  { value: 'pea', label: 'Pea' },
  { value: 'peanut', label: 'Peanut' },
  { value: 'pear', label: 'Pear' },
  { value: 'pineapple', label: 'Pineapple' },
  { value: 'pumpkin', label: 'Pumpkin' },
  { value: 'potato', label: 'Potato' },
  { value: 'tomato', label: 'Tomato' },
  { value: 'watermelon', label: 'Watermelon' },
  { value: 'wheat', label: 'Wheat' },
  { value: 'yuzu', label: 'Yuzu' },
];

// Custom styles for react-select to replace the blue highlight with gray-700
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#4B5563', // Tailwind gray-700 for border
    '&:hover': {
      borderColor: '#374151', // Darker gray-800 on hover
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#374151' : state.isFocused ? '#4B5563' : 'white', // Gray-700 and Gray-800 for selection and hover
    color: state.isSelected ? 'white' : '#111827', // Tailwind gray-900 for text
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151', // Gray-800 for selected text
  }),
};

const DiseaseForm = ({ onFormSubmit }) => {
  const [file, setFile] = useState(null);
  const [output, setOutput] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [plantType, setPlantType] = useState(null);  // Updated to be a select option
  const [isSubmitted, setIsSubmitted] = useState(false);  // State to track form submission

  const handleChange = (event) => {
    setFile({ selectedFile: event.target.files[0] });
    setImageSrc(URL.createObjectURL(event.target.files[0]));
  };

  const handlePlantTypeChange = (selectedOption) => {
    setPlantType(selectedOption);  // Updated to store the selected plant type
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    setIsLoading(true);

    let data = new FormData();
    if (file) {
      data.append('image', file.selectedFile);
    }
    if (plantType) {
      data.append('plant_type', plantType.value);  // Send the plant type value
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/disease`, data);

      setIsDisabled(false);
      setOutput(response.data);  // Store the response data
      setIsLoading(false);
      setIsSubmitted(true); 
      onFormSubmit(true); 
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  // Handler to return to form
  const handleReturnToForm = () => {
    setIsSubmitted(false);
    setOutput(null);  // Reset output
    setFile(null);  // Reset file input
    setImageSrc(null);  // Reset image preview
    setPlantType(null);  // Reset plant type input
    onFormSubmit(false); // Notify parent component that form is reset
  };

  // Helper function to format response with bold titles
  const formatResponse = (response) => {
    return response.split('\n').map((line, index) => {
      if (line.includes('Health Status:')) {
        return <p key={index}><strong>Health Status:</strong> {line.replace('Health Status:', '').trim()}</p>;
      }
      if (line.includes('Disease Description:')) {
        return <p key={index}><strong>Disease Description:</strong> {line.replace('Disease Description:', '').trim()}</p>;
      }
      if (line.includes('Recommendations:')) {
        return <p key={index}><strong>Recommendations:</strong> {line.replace('Recommendations:', '').trim()}</p>;
      }
      if (line.includes('Additional Tips:')) {
        return <p key={index}><strong>Additional Tips:</strong> {line.replace('Additional Tips:', '').trim()}</p>;
      }
      return <p key={index}>{line}</p>;
    });
  };

  return (
    <div className="w-full">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <label
            htmlFor="file-upload"
            className="flex flex-col px-16 py-8 lg:px-56 lg:py-12  items-center justify-center bg-white border border-gray-300 rounded-lg shadow-md cursor-pointer hover:bg-gray-100"
          >
            <FaCloudUploadAlt className="text-gray-700 text-5xl mb-2" />
            <span className="text-gray-700 font-semibold text-xl">SELECT A FILE</span>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleChange}
              required
            />
          </label>

          {imageSrc && (
            <div className="flex justify-center mt-6">
              <img src={imageSrc} alt="Uploaded" className="max-w-[300px] max-h-[300px]" />
            </div>
          )}

          {/* Input for Plant Type */}
          <div className="mt-4 w-full max-w-[400px]">
            <Select
              options={plantTypes}
              value={plantType}
              onChange={handlePlantTypeChange}
              placeholder="Select or type a plant type"
              isClearable
              styles={customStyles}  // Apply the custom styles
            />
          </div>

          <button
            type="submit"
            disabled={isDisabled || !plantType}  // Disable if no plant type is selected
            className="bg-gray-800 text-white py-4 px-12 lg:px-24  rounded-lg hover:bg-gray-700 mt-8 font-bold text-lg "
          >
            {isLoading ? (
              <ReactLoading type="bars" color="#ffffff" height={25} width={25} />
            ) : (
              'Submit'
            )}
          </button>
        </form>
      ) : (
        <div className="mt-8 w-full">
          {/* Render the chatbot response with bold titles */}
          {output && (
            <div className="text-lg">
              {formatResponse(output.response)}
            </div>
          )}

          {/* Button to return to the form */}
          <div className="flex justify-center">
            <button
              onClick={handleReturnToForm}
              className="bg-gray-800 text-white py-2 px-8 rounded-lg hover:bg-gray-700 mt-6 font-bold"
            >
              Return to Form
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiseaseForm;
