import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const XLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-5 h-5 mr-2"
  >
    <path
      fillRule="evenodd"
      d="M20.086 2.91L14.84 10.38 20.897 19h-3.813L12.585 12.67 9.7 19H5.887l6.093-8.632L3.92 2.91h3.813l4.237 5.642L15.4 2.91h3.813z"
    />
  </svg>
);

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-2"> {/* Reduced padding-bottom to pb-2 */}
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">FarmLens</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                Empowering farmers with modern tools and insights to improve
                agricultural efficiency and sustainability.
              </h5>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-4"> {/* Reduced margin-bottom */}
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Stay Connected
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        href="https://x.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-1 text-sm flex items-center" 
                      >
                        <XLogo />
                        X
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://facebook.com/zaineb.boujelbene.9"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-1 text-sm flex items-center"
                      >
                        <FontAwesomeIcon icon={faFacebook} className="mr-2" />
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://linkedin.com/in/zaineb-boujelbene"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-1 text-sm flex items-center"
                      >
                        <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:zaineb.boujelbene@esprit.tn"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-1 text-sm flex items-center"
                      >
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        Email Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-2 border-blueGray-300" /> {/* Reduced margin-top and margin-bottom */}
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-0"> {/* Removed padding-top and padding-bottom */}
                Copyright © {new Date().getFullYear()} FarmLens™. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
