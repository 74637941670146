import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next'; // Import translation hook

function NavBar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const { t, i18n } = useTranslation(); // Use translation hook
    const navigate = useNavigate();

    const handleNavLinkClick = (path) => {
        if (!isAuthenticated) {
            loginWithRedirect(); // Trigger login if not authenticated
        } else {
            navigate(path); // Navigate if authenticated
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value); // Change language on selection
    };

    return (
        <div className="bg-white shadow p-4 flex justify-between items-center">
            <div className="flex items-center lg:ml-36">
                <img src="/plantifylogo_ez5l1p.png" alt="DEMETER Logo" className="h-8 w-8 mr-2" />
                <span className="text-xl font-bold">{t('title', { defaultValue: 'FarmLens' })}</span>
            </div>
            <div className="md:hidden">
                <button onClick={toggleMenu} className="focus:outline-none">
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
                    </svg>
                </button>
            </div>
            <div className="space-x-4 items-center mr-36 hidden md:flex">
                <button
                    className="text-gray-700 font-semibold"
                    onClick={() => handleNavLinkClick("/")}
                >
                    {t('home')}
                </button>
                <button
                    className="text-gray-700 font-semibold"
                    onClick={() => handleNavLinkClick("/disease")}
                >
                    {t('disease')}
                </button>
                <button
                    className="text-gray-700 font-semibold"
                    onClick={() => handleNavLinkClick("/crop")}
                >
                    {t('crop')}
                </button>
                <button
                    className="text-gray-700 font-semibold"
                    onClick={() => handleNavLinkClick("/fertilizer")}
                >
                    {t('fertilizer')}
                </button>
                <button
                    className="text-gray-700 font-semibold"
                    onClick={() => handleNavLinkClick("/nearme")}
                >
                    {t('nearbyServices')}
                </button>
                <button
                    className="text-gray-700 font-semibold"
                    onClick={() => handleNavLinkClick("/market-price")}
                >
                    {t('marketPriceMonitoring')}
                </button>
                <select
                    className="bg-white border border-gray-300 text-black font-semibold py-2 px-4 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 pr-8"
                    onChange={handleLanguageChange} // Handle language change
                >
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="ar">العربية</option>
                </select>
            </div>
            {isMenuOpen && (
                <div className="absolute top-16 right-0 w-full bg-white shadow-lg z-10 md:hidden">
                    <div className="flex flex-col space-y-2 p-4">
                        <button className="text-gray-700 font-semibold" onClick={() => handleNavLinkClick("/")}>
                            {t('home')}
                        </button>
                        <button className="text-gray-700 font-semibold" onClick={() => handleNavLinkClick("/disease")}>
                            {t('disease')}
                        </button>
                        <button className="text-gray-700 font-semibold" onClick={() => handleNavLinkClick("/crop")}>
                            {t('crop')}
                        </button>
                        <button className="text-gray-700 font-semibold" onClick={() => handleNavLinkClick("/fertilizer")}>
                            {t('fertilizer')}
                        </button>
                        <button className="text-gray-700 font-semibold" onClick={() => handleNavLinkClick("/nearme")}>
                            {t('nearbyServices')}
                        </button>
                        <button className="text-gray-700 font-semibold" onClick={() => handleNavLinkClick("/market-price")}>
                            {t('marketPriceMonitoring')}
                        </button>
                        <select
                            className="bg-white border border-gray-300 text-black font-semibold py-2 px-4 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 pr-8"
                            onChange={handleLanguageChange}
                        >
                            <option value="en">English</option>
                            <option value="fr">Français</option>
                            <option value="ar">العربية</option>
                        </select>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NavBar;
