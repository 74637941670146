import React, { useState, useEffect } from 'react';
import NewsItem from '../components/NewsItem';
import axios from 'axios';

const News = () => {
    const [totalNews, setTotalNews] = useState([]);
    const [count, setCount] = useState(0);

    // Your NewsAPI.org API key
    const apiKey = 'd623894ac46f41648630ac9bebae1e87';

    const fetchNews = () => {
        axios
            .get(`https://newsapi.org/v2/everything?q=agriculture&apiKey=${apiKey}`)
            .then((response) => {
                setTotalNews(response.data.articles);
                console.log(response.data.articles);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchNews();
    }, []);

    const itemsPerPage = 4;

    const handlePrev = () => {
        if (count > 0) {
            setCount(count - itemsPerPage);
        }
    };

    const handleNext = () => {
        if (count + itemsPerPage < totalNews.length) {
            setCount(count + itemsPerPage);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-800 px-2">
            <div className="w-full max-w-screen-lg mt-16">
                <div className="flex flex-wrap justify-between gap-4">
                    {totalNews.slice(count, count + 2).map((news) => {
                        return (
                            <div key={news.publishedAt} className="w-[calc(50%-8px)] mb-4">
                                <NewsItem
                                    newTitle={news.title}
                                    newsImgUrl={news.urlToImage}
                                    newsUrl={news.url}
                                    newsDesc={news.description}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="flex flex-wrap justify-between gap-4">
                    {totalNews.slice(count + 2, count + 4).map((news) => {
                        return (
                            <div key={news.publishedAt} className="w-[calc(50%-8px)] mb-4">
                                <NewsItem
                                    newTitle={news.title}
                                    newsImgUrl={news.urlToImage}
                                    newsUrl={news.url}
                                    newsDesc={news.description}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="flex flex-col items-center mt-6">
                    <div className="inline-flex">
                        <button
                            className="px-6 py-3 mx-2 text-md font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900"
                            onClick={handlePrev}
                        >
                            Prev
                        </button>
                        <button
                            className="px-6 py-3 text-md font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
