import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const WeatherPrediction = () => {
  const countryCityData = {
    Tunisia: ["Tunis", "Sfax", "Sousse", "Monastir", "Gabes","Nabeul","Kelibia","Ksar Ghilane","Douz","Mahdia","Beja","Carthage","Sidi Bou Said","Monastir","El Jem","Zriba El Alia","Zaghouan"],
    Algeria: ["Algiers", "Oran", "Constantine", "Annaba"],
    Morocco: ["Casablanca", "Rabat", "Marrakech", "Tangier"],
    France: ["Paris", "Lyon", "Marseille", "Nice"],
  };

  const countries = Object.keys(countryCityData);

  const [formData, setFormData] = useState({
    country: "",
    city: "",
  });

  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCountryChange = (event) => {
    setFormData({
      ...formData,
      country: event.target.value,
      city: "", // Reset city when country changes
    });
  };

  const handleCityChange = (event) => {
    setFormData({
      ...formData,
      city: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/weather-predict`, {
        location: formData.city, // Send city for prediction
      });
      setOutput({ ...response.data, city: formData.city }); // Add city to output
      setIsLoading(false);
      setIsDisabled(false);
    } catch (error) {
      console.error(error);
      alert('Invalid City Name or Failed to Fetch Data');
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Upper Section */}
      

      {/* Container */}
      <div className="relative flex flex-col items-center">
        <p className="text-white text-3xl font-bold text-center mb-52 mt-12">
          {!output ? 'Enter Details To Predict Weather' : 'Predicted Weather for ' + output.city}
        </p>
        <div className="bg-white p-8 rounded shadow-lg absolute mt-32 mx-8 w-[calc(100%-6rem)]">
          {!output ? (
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6 h-full w-full">
              {/* Country and City Dropdowns on the same line */}
              <div className="col-span-2 flex space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="country">
                    COUNTRY
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    value={formData.country}
                    onChange={handleCountryChange}
                  >
                    <option value="" disabled>Select your country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>

                {formData.country && (
                  <div className="w-1/2">
                    <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="city">
                      CITY
                    </label>
                    <select
                      id="city"
                      name="city"
                      className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                      value={formData.city}
                      onChange={handleCityChange}
                    >
                      <option value="" disabled>Select your city</option>
                      {countryCityData[formData.country].map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="col-span-2 flex justify-center mt-6">
                <button
                  type="submit"
                  className="bg-gray-800 text-white py-4 px-12 lg:px-24 rounded-lg hover:bg-gray-700 mt-4 font-bold text-lg"
                  disabled={isDisabled}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          ) : (
            <div className="mt-8 p-4 text-lg text-green bg-white rounded-lg overflow-x-auto">
              <p className="font-bold mb-4">Analysis for {output.city}:</p>
              <p>{output.analysis}</p>

              <div className="mt-8">
                <h3 className="text-lg font-bold mb-4">Weather prediction 🌤️ for {output.city}</h3>
                <div className="overflow-y-auto rounded-lg" >
                  <table className="table-auto w-full">
                    <thead className="bg-slate-700 text-white">
                      <tr>
                        <th className="py-2 px-4 border-b border-gray-200 text-left text-xs font-semibold uppercase">Date</th>
                        <th className="py-2 px-4 border-b border-gray-200 text-left text-xs font-semibold uppercase">Temperature</th>
                        <th className="py-2 px-4 border-b border-gray-200 text-left text-xs font-semibold uppercase">Humidity</th>
                        <th className="py-2 px-4 border-b border-gray-200 text-left text-xs font-semibold uppercase">Wind</th>
                      </tr>
                    </thead>
                    <tbody className="bg-slate-600 text-white">
                      {output.predictions.map((prediction, index) => (
                        <tr key={index}>
                          <td className="py-2 px-4 border-b border-gray-200">{prediction.date}</td>
                          <td className="py-2 px-4 border-b border-gray-200">{prediction.temperature} °C 🌡️</td>
                          <td className="py-2 px-4 border-b border-gray-200">{prediction.humidity} g.m<sup>-3</sup></td>
                          <td className="py-2 px-4 border-b border-gray-200">{prediction.wind} m/s</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Lower Section */}
      <div className="bg-gray-100 flex-1"></div>
    </div>
  );
};

export default WeatherPrediction;
