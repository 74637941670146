import React from "react";
import SideBar from "../components/sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import BurgerMenu from "./sidebar_small_screen";

function LayoutPages() {
  const { logout, user, isAuthenticated } = useAuth0();
  const location = useLocation();

  // Create a map to store page-specific titles
  const pageTitles = {
    "/disease": "DISEASE DETECTION",
    "/crop": "CROP RECOMMENDATION",
    "/fertilizer": "FERTILIZER RECOMMENDATION",
    "/market-price": "MARKET PRICE", //monitoring
    "/nearme": "FIND NEARBY SERVICES",
    "/weather": "WEATHER PREDICTION",
    "/news": "AGRICULTURE NEWS",
    "/chatbot": "AGRI CHATBOT",
  };

  // Get the title based on the current location, fallback to a default
  const currentTitle = pageTitles[location.pathname] || "AGRICULTURE APP";

  return (
    <div className="min-h-screen flex ">
      <SideBar />
      <BurgerMenu/>
      <div className="flex-1 bg-gray-800 relative">
        <div className="w-full flex flex-col min-h-screen">
            
          <div className="bg-gray-800 text-white p-4 flex items-center justify-between">
            <div className="flex justify-start items-center">
            <h2 className="text-sm md:text-lg font-semibold pl-8">
                {currentTitle}
              </h2>
            </div>
            <div className="flex items-center pr-8">
              {" "}
              {/* Added padding-right */}
              <button
                className="text-white font-bold pr-4 text-sm md:text-lg "
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                LOG OUT
              </button>
              {isAuthenticated && (
                <img
                  src={user.picture} // Use Google profile image from Auth0
                  alt={user.name} // Alt text as user's name
                  className="w-10 h-10 rounded-full"
                />
              )}
            </div>
          </div>

          
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default LayoutPages;
