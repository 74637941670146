import React, { useState } from 'react';
import DiseaseForm from '../components/DiseaseForm';
import { useAuth0 } from "@auth0/auth0-react";


const DiseaseDetection = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission state

  const handleFormSubmit = (submitted) => {
    setIsSubmitted(submitted);  // Update title based on form submission
  };

  return (
    <div className=" flex flex-col min-h-screen" >

      <div className=" flex flex-col items-center">
        
        <p className="bg-gray-800 text-white text-lg lg:text-3xl font-bold text-center mb-52 mt-12 ">
          {!isSubmitted ? 'Upload Image to detect crop disease' : 'Disease Detection Results'}
        </p>
        <div className="bg-white p-8 rounded shadow-lg absolute  mt-32 mx-8 w-[calc(100%-6rem)]">
          <div className="upload-section flex flex-col items-center">
            <DiseaseForm onFormSubmit={handleFormSubmit} />
          </div>
        </div>
      </div>

      {/* Lower Section - bg-gray-100 */}
      <div className="bg-gray-100 flex-1"></div>
    </div>
  );
};

export default DiseaseDetection;
