import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BurgerMenu() {
  const { t, i18n } = useTranslation(); // Import i18n and translation functions

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value); // Change the language when a user selects one
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false); // Close drawer when called
  };

  return (
    <div>
      {/* Burger Menu Button */}
      <div className="sm:hidden p-4">
        <button onClick={toggleDrawer} className="focus:outline-none">
          {isDrawerOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-x"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 6l16 0" />
              <path d="M4 12l16 0" />
              <path d="M4 18l16 0" />
            </svg>
          )}
        </button>
      </div>

      {/* Drawer Content */}
      <div
        className={`fixed inset-y-0 left-0 z-50 transform bg-white p-5 w-80 transition-transform duration-300 ease-in-out ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        } lg:hidden`}
      >
        <button
          onClick={toggleDrawer}
          className="focus:outline-none absolute top-4 right-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-x"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>{" "}
        </button>
        <NavLink
          to="/"
          className="flex items-center mb-6 pl-4"
          onClick={closeDrawer}
        >
          <img
            src="/plantifylogo_ez5l1p.png"
            alt="Logo"
            className="w-12 h-12 mr-2"
          />
          <h2 className="text-2xl font-bold">FarmLens</h2>
        </NavLink>
        <hr className="mb-10 border-gray-300" />

        {/* Features Title */}
        <h3 className="text-sm font-bold mb-6 text-gray-600">
          {t("features")}
        </h3>

        {/* Navigation Links */}
        <nav className="flex flex-col space-y-4">
          <NavLink
            to="/disease"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-bug mr-2 text-sm"></i>
            {t("disease")}
          </NavLink>

          <NavLink
            to="/crop"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-apple-alt mr-2 text-sm"></i>
            {t("crop")}
          </NavLink>

          <NavLink
            to="/fertilizer"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-seedling mr-2 text-sm"></i>
            {t("fertilizer")}
          </NavLink>

          <NavLink
            to="/market-price"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-coins mr-2 text-sm"></i>
            {t("MARKET PRICE")}
          </NavLink>

          <NavLink
            to="/nearme"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-map-marked mr-2 text-sm"></i>
            {t("NEARBY SERVICES")}
          </NavLink>

          <NavLink
            to="/weather"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-cloud-sun mr-2 text-sm"></i>
            {t("weather")}
          </NavLink>

          <NavLink
            to="/news"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-newspaper mr-2 text-sm"></i>
            {t("news")}
          </NavLink>

          {/* Chatbot NavLink */}
          <NavLink
            to="/chatbot"
            className={({ isActive }) =>
              isActive
                ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
                : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
            }
            onClick={closeDrawer}
          >
            <i className="fas fa-comments mr-2 text-sm"></i>
            {t("ASK FARMLENS")}
          </NavLink>
        </nav>

        <hr className="my-10 border-gray-300" />

        {/* Language Selector */}
        <div className="w-full">
          <label
            htmlFor="language-select"
            className="block mb-2 text-sm font-bold text-gray-800"
          >
            {t("selectLanguage")}
          </label>
          <select
            id="language-select"
            onChange={handleLanguageChange} // Handle language change
            className="w-full py-2.5 px-4 rounded bg-gray-200 hover:bg-gray-300 transition duration-200 text-gray-800 font-bold"
          >
            <option value="ar">Arabic</option>
            <option value="en">English</option>
            <option value="fr">French</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
