import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import ar from './i18n/ar.json';

// Configure i18n
i18n
  .use(LanguageDetector) // Auto-detect the language from the browser
  .use(initReactI18next) // Bind react-i18next to the i18n instance
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      ar: { translation: ar },
    },
    fallbackLng: 'en', // Default language
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'], // Cache the language
    },
    interpolation: {
      escapeValue: false, // React already escapes values, so no need for it here
    },
  });

export default i18n;
