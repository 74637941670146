import React from 'react';

const NewsItem = ({ newTitle, newsImgUrl, newsUrl, newsDesc }) => {
    return (
        <div className="flex flex-col h-full rounded overflow-hidden shadow-lg bg-gray-500 text-white">
            <img
                src={newsImgUrl}
                alt={newTitle}
                className="w-full h-36 object-cover"
            />
            <div className="p-3 flex-grow">
                <h3 className="text-md font-semibold mb-1">{newTitle}</h3>
                <p className="text-xs mb-3">{newsDesc}</p>
            </div>
            <div className="px-4 py-2">
                <a
                    href={newsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded inline-flex justify-center"
                >
                    Read More...
                </a>
            </div>
        </div>
    );
};

export default NewsItem;
