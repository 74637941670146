import React from 'react';
import { Outlet } from 'react-router-dom';

function LayoutLogin() {
  return (
    <div className="min-h-screen flex">

      {/* Main content area for the login */}
      <div className="flex-1 bg-gray-800 flex justify-center items-center">
        <div className="mx-auto bg-white max-w-lg my-2 p-16 shadow-md rounded-lg bg-clip-border">
            <Outlet/>
          
        </div>
      </div>
    </div>
  );
}

export default LayoutLogin;

