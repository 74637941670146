import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SideBar() {
  const { t, i18n } = useTranslation();  // Import i18n and translation functions

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);  // Change the language when a user selects one
  };

  return (
    <div className="bg-white text-gray-800 w-80 min-h-screen p-5 hidden sm:block">
      {/* Logo and Title */}
      <NavLink to="/" className="flex items-center mb-6 pl-4"> 
        <img src="/plantifylogo_ez5l1p.png" alt="Logo" className="w-12 h-12 mr-2" />
        <h2 className="text-2xl font-bold">{t('title', { defaultValue: 'DEMETER' })}</h2>
      </NavLink>
      <hr className="mb-10 border-gray-300" />
      
      {/* Features Title */}
      <h3 className="text-sm font-bold mb-6 text-gray-600">{t('features')}</h3>

      {/* Navigation Links */}
      <nav className="flex flex-col space-y-4">
        <NavLink
          to="/disease"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-bug mr-2 text-sm"></i>
          {t('disease')}
        </NavLink>

        <NavLink
          to="/crop"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-apple-alt mr-2 text-sm"></i>
          {t('crop')}
        </NavLink>

        <NavLink
          to="/fertilizer"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-seedling mr-2 text-sm"></i>
          {t('fertilizer')}
        </NavLink>

        <NavLink
          to="/market-price"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-coins mr-2 text-sm"></i>
          {t('marketPrice')}
        </NavLink>

        <NavLink
          to="/nearme"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-map-marked mr-2 text-sm"></i>
          {t('nearbyServices')}
        </NavLink>

        <NavLink
          to="/weather"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-cloud-sun mr-2 text-sm"></i>
          {t('weather')}
        </NavLink>

        <NavLink
          to="/news"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-newspaper mr-2 text-sm"></i>
          {t('news')}
        </NavLink>

        {/* Chatbot NavLink */}
        <NavLink
          to="/chatbot"
          className={({ isActive }) =>
            isActive
              ? "flex items-center py-2.5 px-4 rounded transition duration-200 text-customBlue font-bold"
              : "flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-customBlue font-bold"
          }
        >
          <i className="fas fa-comments mr-2 text-sm"></i>
          {t('askDemeter')}
        </NavLink>
      </nav>

      <hr className="my-10 border-gray-300" />
      
      {/* Language Selector */}
      <div className="w-full">
        <label htmlFor="language-select" className="block mb-2 text-sm font-bold text-gray-800">{t('selectLanguage')}</label>
        <select
          id="language-select"
          onChange={handleLanguageChange}  // Handle language change
          className="w-full py-2.5 px-4 rounded bg-gray-200 hover:bg-gray-300 transition duration-200 text-gray-800 font-bold"
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
          <option value="ar">العربية</option>
        </select>
      </div>
    </div>
  );
}

export default SideBar;
