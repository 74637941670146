import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";

const Chatbot = ({ messages, handleSendMessage }) => {
  const [userMessage, setUserMessage] = useState('');
  const { user, isAuthenticated, isLoading } = useAuth0();

  const handleInputChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleSend = () => {
    handleSendMessage(userMessage);
    setUserMessage(""); // Clear input field
  };

  const sanitizeHTML = (messageText) => {
    // Sanitize and allow only <br> for line breaks
    return { __html: DOMPurify.sanitize(messageText, { ALLOWED_TAGS: ['br'] }) };
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div className="flex flex-col justify-between h-screen bg-gray-100 p-0">
      {/* Chat Background */}
      <div className="flex-1 overflow-y-auto bg-cover bg-center relative" 
           style={{ backgroundImage: "url('/output-onlinepngtools.png')", 
                    backgroundSize: "35%", 
                    backgroundRepeat: "no-repeat", 
                    maxHeight: "calc(100vh - 120px)" }}> {/* Adjust height */}
        <div className="relative z-10 p-4">
          {messages.map((message, index) => (
            <div key={index} className={`flex items-center mb-4 ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
              {message.sender === 'bot' && (
                <img src="/botprofile.png" alt="Chatbot" className="w-10 h-10 rounded-full mr-3" />
              )}
              <span 
                className={`inline-block p-3 rounded-lg`} 
                style={{ 
                  backgroundColor: message.sender === 'user' ? '#4A5568' : '#E2E8F0', 
                  color: message.sender === 'user' ? 'white' : 'black',
                  whiteSpace: 'pre-wrap',  // Respect line breaks
                  wordWrap: 'break-word',  // Handle long words
                  overflowWrap: 'break-word'  // Ensure word wrapping works properly
                }}
              >
                {/* Use dangerouslySetInnerHTML to render sanitized HTML */}
                <span dangerouslySetInnerHTML={sanitizeHTML(message.text)} />
              </span>
              {message.sender === 'user' && isAuthenticated && (
                <img
                  src={user.picture}
                  alt={user.name}
                  className="w-10 h-10 rounded-full ml-3"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Input and Send Button */}
      <div className="p-4 flex flex-col bg-white sticky bottom-0"> {/* Wrap in flex-col */}
        <div className="flex">
          <input
            type="text"
            className="flex-1 p-3 border-2 border-gray-800 rounded-lg shadow-inner focus:outline-none"
            placeholder="Type your message..."
            value={userMessage}
            onChange={handleInputChange}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <button className="ml-4 bg-gray-800 text-white p-3 rounded-lg shadow flex items-center justify-center" onClick={handleSend}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
        <p className="text-gray-500 text-sm text-center mt-2">
          FarmLens Bot can make mistakes. Check important info.
        </p>
      </div>
    </div>
  );
};

export default Chatbot;
