import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const CropRecommendation = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [formData, setFormData] = useState({
    nitrogen: "",
    phosphorus: "",
    potassium: "",
    temperature: "",
    ph: "",
    rainfall: "",
  });

  const [recommendation, setRecommendation] = useState([]);  // Stores crop recommendations
  const [submitted, setSubmitted] = useState(false);  // Track if form has been submitted
  const [selectedCropExplanation, setSelectedCropExplanation] = useState("");  // Explanation of the selected crop
  const navigate = useNavigate();

  // Fetch sensor data from the backend and populate the form fields
  // useEffect(() => {
  //   const fetchSensorData = async () => {
  //     try {
  //       const response = await fetch("http://localhost:5000/sensor-data");
  //       const sensorData = await response.json();

  //       if (sensorData) {
  //         setFormData({
  //           nitrogen: sensorData.nitrogen || "",
  //           phosphorus: sensorData.phosphorus || "",
  //           potassium: sensorData.potassium || "",
  //           temperature: sensorData.temperature || "",
  //           ph: sensorData.ph || "",
  //           rainfall: sensorData.rainfall || "",
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching sensor data:", error);
  //     }
  //   };

  //   fetchSensorData();  // Fetch sensor data when the component loads
  // }, []);  // Empty dependency array ensures it runs only once after the component mounts

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send data to Flask backend for crop recommendation
    const response = await fetch(`${API_BASE_URL}/crop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const result = await response.json();
    console.log(result);  // Debug: Log the result

    if (result.crops) {
      setRecommendation(result.crops);  // Update recommendations
      setSubmitted(true);  // Set form submission state to true
    } else {
      setRecommendation([]);  // Empty array if no crops data
    }
  };

  const handleCropClick = (explanation) => {
    setSelectedCropExplanation(explanation);  // Update the selected crop explanation
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col items-center">
        {!submitted ? (
          <>
            <p className="text-white text-lg lg:text-3xl font-bold text-center mb-52 mt-12">
              Enter Details To Get Crop Recommendations
            </p>
            <div className="bg-white p-8 rounded shadow-lg absolute mt-32 mx-8 w-[calc(100%-6rem)]">
              <form className="flex flex-col md:grid md:grid-cols-2 gap-6 h-full w-full" onSubmit={handleSubmit}>
                {/* Nitrogen Field */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="nitrogen">
                    NITROGEN (IN PPM)
                  </label>
                  <input
                    type="number"
                    id="nitrogen"
                    name="nitrogen"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER NITROGEN VALUE"
                    value={formData.nitrogen}
                    onChange={handleChange}
                  />
                </div>

                {/* Phosphorus Field */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="phosphorus">
                    PHOSPHORUS (IN PPM)
                  </label>
                  <input
                    type="number"
                    id="phosphorus"
                    name="phosphorus"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER PHOSPHORUS VALUE"
                    value={formData.phosphorus}
                    onChange={handleChange}
                  />
                </div>

                {/* Potassium Field */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="potassium">
                    POTASSIUM (IN PPM)
                  </label>
                  <input
                    type="number"
                    id="potassium"
                    name="potassium"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER POTASSIUM VALUE"
                    value={formData.potassium}
                    onChange={handleChange}
                  />
                </div>

                {/* Temperature Field */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="temperature">
                    TEMPERATURE (°C)
                  </label>
                  <input
                    type="number"
                    id="temperature"
                    name="temperature"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER TEMPERATURE VALUE"
                    value={formData.temperature}
                    onChange={handleChange}
                  />
                </div>

                {/* pH Field */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="ph">
                    PH
                  </label>
                  <input
                    type="number"
                    id="ph"
                    name="ph"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER PH VALUE"
                    value={formData.ph}
                    onChange={handleChange}
                  />
                </div>

                {/* Rainfall Field */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="rainfall">
                    RAINFALL (MM)
                  </label>
                  <input
                    type="number"
                    id="rainfall"
                    name="rainfall"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER RAINFALL VALUE"
                    value={formData.rainfall}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-2 flex justify-center mt-6">
                  <button className="bg-gray-800 text-white py-4 px-24 rounded-lg hover:bg-gray-700 mt-4 font-bold text-lg">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className="bg-white p-8 rounded shadow-lg mt-12 mx-8 w-[calc(100%-6rem)]">
            <h3 className="text-xl font-bold text-center">Recommended Crops:</h3>
            <table className="min-w-full mt-4">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Sr. No</th>
                  <th className="border px-4 py-2">Crop Name</th>
                  <th className="border px-4 py-2">Crop Image</th>
                </tr>
              </thead>
              <tbody>
                {recommendation.map((crop, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleCropClick(crop.explanation)}
                        className="text-blue-600 hover:underline"
                      >
                        {crop.name}
                      </button>
                    </td>
                    <td className="border px-4 py-2">
                      <img src={crop.image_url} alt={crop.name} className="w-16 h-16" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Show the selected crop explanation below the table */}
            {selectedCropExplanation && (
              <div className="mt-8 p-4 bg-gray-100 rounded-lg">
                <h4 className="text-lg font-bold">Crop Explanation:</h4>
                <p>{selectedCropExplanation}</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="bg-gray-100 flex-1"></div>
    </div>
  );
};

export default CropRecommendation;
