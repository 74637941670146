import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register chart elements for Chart.js
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MarketPrice = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [formData, setFormData] = useState({
    commodity: "",
    region: "",
    date: "",
  });

  const [priceData, setPriceData] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); // Store historical price data for trends
  const [aiAnalysis, setAiAnalysis] = useState(""); // Store AI analysis
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission state
  const [exchangeRate, setExchangeRate] = useState(1); // Exchange rate to convert to local currency
  const [currencySymbol, setCurrencySymbol] = useState("$"); // Default to USD

  // Define handleChange function
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsSubmitted(true);

    try {
      const response = await fetch(`${API_BASE_URL}/market-price`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result && result.prices) {
        setPriceData(result.prices);
        setPriceHistory(result.price_trends || []); // Assuming the backend sends a price_trends array
      }

      setAiAnalysis(result.ai_analysis);

      // Fetch currency conversion based on region after the data is loaded
      fetchCurrencyConversion(formData.region);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsLoading(false);
  };

  // Fetch currency exchange rates based on the region (e.g., USD to local currency)
  const fetchCurrencyConversion = async (region) => {
    // For simplicity, we can use hardcoded conversion values or fetch from a live API
    let currency = "USD"; // Default currency

    switch (region.toLowerCase()) {
      case "europe":
        currency = "EUR";
        setCurrencySymbol("€");
        break;
      case "japan":
        currency = "JPY";
        setCurrencySymbol("¥");
        break;
      case "tunisia":
        currency = "TND";
        setCurrencySymbol("TND");
        break;
      // Add other regions and their respective currencies
      default:
        currency = "USD";
        setCurrencySymbol("$");
    }

    // Example: Fetch exchange rate from a live API (e.g., Alpha Vantage or ExchangeRatesAPI)
    try {
      const response = await fetch(
        `https://api.exchangerate-api.com/v4/latest/${currency}`
      );
      const data = await response.json();
      setExchangeRate(data.rates.USD || 1); // Use USD as the base, or update as needed
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setExchangeRate(1); // Default to no conversion if error
    }
  };

  // Convert price from tons to kilograms, and apply currency conversion
  const convertPrice = (pricePerTon) => {
    const pricePerKg = pricePerTon / 1000; // Convert ton to kg
    return (pricePerKg * exchangeRate).toFixed(2); // Convert using exchange rate and round to 2 decimals
  };

  // Prepare data for the Line Chart
  const getPriceTrendData = () => {
    const labels = priceHistory.map((item) => item.date); // Dates for the x-axis
    const data = priceHistory.map((item) => parseFloat(item.price)); // Prices for the y-axis

    return {
      labels,
      datasets: [
        {
          label: `Price (${currencySymbol}/kg)`,
          data,
          fill: false,
          borderColor: "rgba(75, 192, 192, 1)",
          tension: 0.1,
        },
      ],
    };
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* <div className="bg-gray-800 text-white p-4 flex items-center justify-between" style={{ minHeight: '40vh', paddingBottom: '300px' }}>
        <div className="flex justify-start items-center">
          <h2 className="text-xl font-semibold pl-8">MARKET PRICE MONITORING</h2>
        </div>
        <div className="flex items-center pr-8">
          <button
            className="text-white font-bold pr-4"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            LOG OUT
          </button>

          {isAuthenticated && (
            <img
              src={user.picture} // Use Google profile image from Auth0
              alt={user.name}     // Alt text as user's name
              className="w-10 h-10 rounded-full"
            />
          )}
        </div>
      </div> */}

      {/* Container spanning both sections */}
      <div className="relative flex flex-col items-center">
        {/* Dynamically change the title based on form submission */}
        <p className="text-white text-lg lg:text-3xl font-bold text-center mb-52 mt-12">
          {!isSubmitted
            ? "Enter Details to Monitor Market Prices"
            : `${formData.region}'s Market Prices`}
        </p>

        <div className="bg-white p-8 rounded shadow-lg absolute mt-32 mx-8 w-[calc(100%-6rem)]">
          {/* Conditional rendering: show search form before submission, show results after */}
          {!isSubmitted ? (
            // Search Form
            <div className="p-8 bg-white shadow-md rounded-lg mb-8">
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
              >
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Search Commodity
                  </label>
                  <input
                    name="commodity"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                    type="text"
                    placeholder="e.g., Rice, Wheat"
                    value={formData.commodity}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Region/Market
                  </label>
                  <input
                    name="region"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                    type="text"
                    placeholder="e.g., California, Tunisia"
                    value={formData.region}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Date Range
                  </label>
                  <input
                    name="date"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex items-end">
                  <button className="bg-gray-800 text-white px-4 py-2 rounded-lg">
                    Search
                  </button>
                </div>
              </form>
            </div>
          ) : isLoading ? (
            // Loading Indicator
            <div className="p-8 bg-white shadow-md rounded-lg text-center">
              Loading...
            </div>
          ) : (
            // Show Results (Table and Graph) after search
            <>
              {priceData && priceData.length > 0 ? (
                <div className="lg:p-8 bg-white lg:shadow-md rounded-lg">
                  <h3 className="text-lg font-bold text-gray-700 mb-4">
                    Current Market Prices
                  </h3>
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full">
                      <thead>
                        <tr>
                          <th className="px-4 py-2">Commodity</th>
                          <th className="px-4 py-2">Region</th>
                          <th className="px-4 py-2">
                            Price ({currencySymbol}/kg)
                          </th>{" "}
                          {/* Adjusted for currency and kg */}
                          <th className="px-4 py-2">Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceData.map((item, index) => (
                          <tr key={index}>
                            <td className="border px-4 py-2">
                              {item.commodity}
                            </td>
                            <td className="border px-4 py-2">{item.region}</td>
                            <td className="border px-4 py-2">
                              {convertPrice(item.price)}
                            </td>{" "}
                            {/* Convert price */}
                            <td className="border px-4 py-2">{item.change}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="p-8 bg-white shadow-md rounded-lg text-center">
                  No data available
                </div>
              )}

              <div className="p-8 bg-white shadow-md rounded-lg mt-8">
                <h3 className="text-lg font-bold text-gray-700 mb-4">
                  Price Trends
                </h3>
                <div className="w-full h-64 sm:h-96">
                  {" "}
                  {/* Adjust the height for different screen sizes */}
                  {priceHistory.length > 0 ? (
                    <Line
                      data={getPriceTrendData()}
                      options={{
                        maintainAspectRatio: false, // Allows the chart to resize dynamically
                        responsive: true, // Ensure it is responsive
                        scales: {
                          x: {
                            ticks: {
                              font: {
                                size: window.innerWidth < 640 ? 10 : 14, // Adjust tick size for small screens
                              },
                            },
                          },
                          y: {
                            ticks: {
                              font: {
                                size: window.innerWidth < 640 ? 10 : 14, // Adjust tick size for small screens
                              },
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <p className="text-center">No price trend data available</p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="bg-gray-100 flex-1"></div>
    </div>
  );
};

export default MarketPrice;
