import React from 'react';
import NearMeForm from '../components/NearMeForm'; // Assuming you create this new component
import { useAuth0 } from "@auth0/auth0-react";

const NearMe = () => {
  const { logout, user, isAuthenticated } = useAuth0();

  return (
    <div className="min-h-screen flex flex-col">
      {/* Upper Section */}
    

      {/* Container */}
      <div className="relative flex flex-col items-center">
        <p className="text-white text-lg lg:text-3xl font-bold text-center mb-52 mt-12">
          Enter Town Name To Find Nearby Services
        </p>
        <div className="bg-white p-8 rounded shadow-lg absolute mt-32 mx-8 w-[calc(100%-6rem)]">
          <NearMeForm />
        </div>
      </div>

      {/* Lower Section */}
      <div className="bg-gray-100 flex-1"></div>
    </div>
  );
};

export default NearMe;
