import React from 'react';
import NavBar from '../components/navbar';
import { Outlet } from 'react-router-dom';

function LayoutDashboard() {
    return (
        <div className="min-h-screen overflow-hidden">
            <NavBar />
            <main className="bg-gray-100 min-h-screen p-4 md:p-6 2xl:p-10">
                <Outlet />
            </main>
        </div>
    );
}

export default LayoutDashboard;
