import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LayoutDashboard from './components/layout';
import LayoutPages from './components/layoutpages';
import DiseaseDetection from './pages/disease';
import Home from './pages/home';
import Crop from './pages/crop';
import Fertilizer from './pages/fertilizer';
import WeatherPrediction from './pages/weather';
import News from './pages/news';
import Labs from './pages/nearme';
import { useTranslation, I18nextProvider } from 'react-i18next';
import './i18n';
import LayoutLogin from './components/layoutlogin';
import Login from './pages/login';
import MarketPrice from './pages/price';
import Chatbot from './pages/chatbot';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Get the current language
    const currentLang = i18n.language;

    // Set the `lang` attribute on the <html> element
    document.documentElement.lang = currentLang;

    // Set the `dir` attribute to RTL for Arabic, LTR for others
    document.documentElement.dir = currentLang === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);
  // Centralized state for messages and chatbot input
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hi! How can I assist you today?' },
  ]);

  const handleSendMessage = async (userMessage) => {
    if (userMessage.trim() !== '') {
      const newMessages = [...messages, { sender: 'user', text: userMessage }];
      setMessages(newMessages);

      // Send user message to the backend chatbot
      const response = await fetch(`${API_BASE_URL}/chatbot`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question: userMessage }),
      });

      const data = await response.json();
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: data.response },
      ]);
    }
  };
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="App">
          <Routes>
            
            <Route path="/login" element={<LayoutLogin/>}>
              <Route path='' element={<Login/>} />
            </Route>

            <Route path='/' element={<LayoutDashboard/>}>
              <Route path="/" element={<Home messages={messages} handleSendMessage={handleSendMessage} />} />
            </Route>
            
            <Route path='/' element={<LayoutPages/>}>
              <Route path="disease" element={<DiseaseDetection/>} />
              <Route path="crop" element={<Crop/>} />
              <Route path="fertilizer" element={<Fertilizer/>} />
              <Route path="market-price" element={<MarketPrice/>} />
              <Route path="nearme" element={<Labs/>} />
              <Route path="weather" element={<WeatherPrediction/>} />
              <Route path="news" element={<News/>} />
              <Route path="chatbot" element={<Chatbot messages={messages} handleSendMessage={handleSendMessage} />} />
            </Route>

          </Routes>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
