import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LayoutLogin from "../components/layoutlogin";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};
//   const navigate = useNavigate(); // Use useNavigate for navigation
//   const [providers, setProviders] = useState(null);

//   useEffect(() => {
//     // Mock function to simulate getting providers (like Google, GitHub)
//     const getProviders = () => {
//       return {
//         google: { id: "google" },
//       };
//     };
//     setProviders(getProviders());
//   }, []);

//   const signIn = (providerId, options) => {
//     // You can add sign-in logic here if needed
//     // After successful sign-in, navigate to the home page
//     navigate("/");
//   };

//   return (
//     <div className="">
//       <div className="text-center mb-3">
//         <h6 className="text-blueGray-500 text-sm font-bold">Sign in with</h6>
//       </div>
//       <div className="btn-wrapper text-center">
//         <button
//           className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
//           type="button"
//           onClick={() =>
//             signIn(providers.google.id, {
//               callbackUrl: `${window.location.origin}/disease`,
//             })
//           }
//         >
//           <img alt="..." className="w-5 mr-1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png" />
//           Google
//         </button>
//       </div>
//       <hr className="mt-6 border-b-1 border-blueGray-300" />
//       <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
//         <div className="text-blueGray-400 text-center mb-3 font-bold">
//           <small>Or sign in with credentials</small>
//         </div>
//         <form className="flex flex-col gap-2 mb-0 mt-4 p-4 sm:p-6 lg:p-2">
//           <div className="relative w-full mb-3">
//             <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
//               Username
//             </label>
//             <div className="relative mb-4">
//               <input
//                 type="text"
//                 className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
//                 placeholder="Username"
//               />
//               <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="size-4 text-gray-400"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
//                   />
//                 </svg>
//               </span>
//             </div>
//           </div>

//           <div className="relative w-full mb-3">
//             <label
//               className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
//               htmlFor="grid-password"
//             >
//               Password
//             </label>
//             <div className="relative mb-4">
//               <input
//                 type="password"
//                 className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
//                 placeholder="Password"
//               />
//               <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="size-4 text-gray-400"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
//                   />
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
//                   />
//                 </svg>
//               </span>
//             </div>
//           </div>
//           <div>
//             <label className="inline-flex items-center cursor-pointer">
//               <input
//                 id="customCheckLogin"
//                 type="checkbox"
//                 className="form-checkbox border border-gray-400 rounded-md text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-2 focus:ring-blue-500 focus:outline-none"
//               />
//               <span className="ml-2 text-sm font-semibold text-blueGray-600">
//                 Remember me
//               </span>
//             </label>
//           </div>

//           <div className="text-center mt-6">
//             <button
//               className="bg-blueGray-800 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
//               type="button"
//               onClick={() => navigate("/")} // Redirect to home page on Sign In
//             >
//               Sign In
//             </button>
//           </div>
//         </form>
//       </div>
//       <div className="flex flex-wrap mt-6 relative">
//         <div className="w-1/2">
//           <a
//             href="#pablo"
//             onClick={(e) => e.preventDefault()}
//             className="text-blueGray-200"
//           >
//             <small>Forgot password?</small>
//           </a>
//         </div>
//         <div className="w-1/2 text-right">
//           <a
//             href="#pablo"
//             onClick={() => navigate("/auth/register")}
//             className="text-blueGray-200"
//           >
//             <small>Create new account</small>
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Login;
