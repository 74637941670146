import React, { useState, useEffect } from "react";
import axios from "axios";

const NearMeForm = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [output, setOutput] = useState(false);

  // Fetch the countries and their cities using an external API
  useEffect(() => {
    axios.get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => {
        setCountries(response.data.data); // Populate countries from API
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  // Handle country selection
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    
    // Find the cities for the selected country
    const country = countries.find((c) => c.country === selectedCountry);
    setCities(country ? country.cities : []); // Update cities based on country
    setSelectedCity(""); // Reset city when country changes
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOutput(true); // Show the map
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row gap-4 mb-3 w-full justify-evenly ">
          {/* Country Dropdown */}
          <div className="mr-2">
            <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="country">
              Country
            </label>
            <select
              className="border-0 px-8 py-4 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              id="country"
              name="country"
              value={selectedCountry}
              onChange={handleCountryChange}
              required
            >
              <option value="">Select a Country</option>
              {countries.map((country) => (
                <option key={country.country} value={country.country}>
                  {country.country}
                </option>
              ))}
            </select>
          </div>

          {/* City Dropdown */}
          <div className="ml-2">
            <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="city">
              City
            </label>
            <select
              className="border-0 px-8 py-4 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              id="city"
              name="city"
              value={selectedCity}
              onChange={handleCityChange}
              required
              disabled={!selectedCountry} // Disable until a country is selected
            >
              <option value="">Select a City</option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>

          {/* Service Type Dropdown */}
          <div className="ml-2">
            <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="service">
              Service Type
            </label>
            <select
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              id="service"
              name="service"
              value={selectedService}
              onChange={handleServiceChange}
              required
            >
              <option value="">Select a Service</option>
              <option value="Soil Testing Labs">Soil Testing Labs</option>
              <option value="Fertilizer Shops">Fertilizer Shops</option>
              <option value="Nurseries">Nurseries</option>
              <option value="Agriculture Shops">Agriculture Shops</option>
            </select>
          </div>
        </div>

        <div className="flex justify-center my-6">
          <button
            type="submit"
            className="bg-gray-800 text-white py-4 px-12 lg:px-24 rounded-lg hover:bg-gray-700 font-bold text-lg flex items-center justify-center"
            style={{ width: '300px', height: '50px', margin: '2px 0' }}
          >
            Submit
          </button>
        </div>
      </form>

      {/* Map Display */}
      {output && selectedCountry && selectedCity && selectedService && (
        <div className="w-full h-96 overflow-hidden">
          <iframe
            width="100%"
            height="100%"
            style={{ border: 0 }}
            src={`https://maps.google.com/maps?width=100%&height=600&hl=en&q=${selectedService}+${selectedCity}+${selectedCountry}&ie=UTF8&t=&z=14&iwloc=B&output=embed&zoom=0`}
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      )}
    </>
  );
};

export default NearMeForm;
