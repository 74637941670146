import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const FertilizerRecommendation = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [isSubmitted, setIsSubmitted] = useState(false);  // Track form submission state
  const [formData, setFormData] = useState({
    nitrogen: "",
    phosphorus: "",
    potassium: "",
    temperature: "",
    humidity: "",
    ph: "",
    rainfall: "",
    Moist: "",  // Added Moisture
    Crop: "1",  // Default selection for Crop
    Soil: "1",  // Default selection for Soil
  });
  const [response, setResponse] = useState(null);  // Store the backend response

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   const fetchSensorData = async () => {
  //     try {
  //       const result = await fetch("http://localhost:5000/get-sensor-data");
  //       const data = await result.json();
  //       setFormData({
  //         nitrogen: data.nitrogen,
  //         phosphorus: data.phosphorus,
  //         potassium: data.potassium,
  //         temperature: data.temperature,
  //         humidity: data.humidity,
  //         ph: data.pH,
  //         rainfall: data.rainfall,
  //         Moist: data.moisture,
  //         Crop: "1",
  //         Soil: "1",
  //       });
  //     } catch (error) {
  //       console.error("Error fetching sensor data:", error);
  //     }
  //   };

  //   // Fetch every 5 seconds
  //   const intervalId = setInterval(fetchSensorData, 5000);

  //   // Clear the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);


  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const result = await fetch(`${API_BASE_URL}/fertilizer-predict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await result.json();
      console.log("Response from backend:", data);  // Log the response for debugging
      setResponse(data);  // Store the backend response
      setIsSubmitted(true);  // Change state to indicate form submission
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col items-center">
        <p className="bg-gray-800 text-white text-lg lg:text-3xl font-bold text-center mb-52 mt-12 ">
          {!isSubmitted ? 'Enter values to get fertilizer recommendations' : 'Fertilizer Recommendation Results'}
        </p>
        <div className="bg-white p-8 rounded shadow-lg absolute mt-32 mx-8 w-[calc(100%-6rem)]">
          <div className="upload-section flex flex-col items-center">
            {!isSubmitted ? (
              <form className="flex flex-col lg:grid lg:grid-cols-2 gap-6 h-full w-full" onSubmit={handleSubmit}>
                {/* Nitrogen */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="nitrogen">
                    NITROGEN (IN PPM)
                  </label>
                  <input
                    type="number"
                    id="nitrogen"
                    name="nitrogen"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER NITROGEN VALUE"
                    value={formData.nitrogen}
                    onChange={handleChange}
                  />
                </div>

                {/* Phosphorus */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="phosphorus">
                    PHOSPHORUS (IN PPM)
                  </label>
                  <input
                    type="number"
                    id="phosphorus"
                    name="phosphorus"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER PHOSPHORUS VALUE"
                    value={formData.phosphorus}
                    onChange={handleChange}
                  />
                </div>

                {/* Potassium */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="potassium">
                    POTASSIUM (IN PPM)
                  </label>
                  <input
                    type="number"
                    id="potassium"
                    name="potassium"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER POTASSIUM VALUE"
                    value={formData.potassium}
                    onChange={handleChange}
                  />
                </div>

                {/* Temperature */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="temperature">
                    TEMPERATURE (°C)
                  </label>
                  <input
                    type="number"
                    id="temperature"
                    name="temperature"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER TEMPERATURE"
                    value={formData.temperature}
                    onChange={handleChange}
                  />
                </div>

                {/* Humidity */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="humidity">
                    HUMIDITY (%)
                  </label>
                  <input
                    type="number"
                    id="humidity"
                    name="humidity"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER HUMIDITY VALUE"
                    value={formData.humidity}
                    onChange={handleChange}
                  />
                </div>

                {/* pH */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="ph">
                    PH
                  </label>
                  <input
                    type="number"
                    id="ph"
                    name="ph"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER PH VALUE"
                    value={formData.ph}
                    onChange={handleChange}
                  />
                </div>

                {/* Rainfall */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="rainfall">
                    RAINFALL (MM)
                  </label>
                  <input
                    type="number"
                    id="rainfall"
                    name="rainfall"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER RAINFALL VALUE"
                    value={formData.rainfall}
                    onChange={handleChange}
                  />
                </div>

                {/* Moisture next to Rainfall */}
                <div>
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="Moist">
                    MOISTURE (%)
                  </label>
                  <input
                    type="number"
                    id="Moist"
                    name="Moist"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    placeholder="ENTER MOISTURE VALUE"
                    value={formData.Moist}
                    min="0"
                    max="100"
                    onChange={handleChange}
                  />
                </div>

                {/* Crop Type and Soil Type on same line */}
                <div className="col-span-1">
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="Crop">
                    CROP TYPE
                  </label>
                  <select
                    id="Crop"
                    name="Crop"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    value={formData.Crop}
                    onChange={handleChange}
                  >
                    <option value="0">Barley</option>
                    <option value="1" selected>Cotton</option>
                    <option value="2">Ground Nuts</option>
                    <option value="3">Maize</option>
                    <option value="4">Millets</option>
                    <option value="5">Oil seeds</option>
                    <option value="6">Paddy</option>
                    <option value="7">Pulses</option>
                    <option value="8">Sugarcane</option>
                    <option value="9">Tobacco</option>
                    <option value="10">Wheat</option>
                  </select>
                </div>

                <div className="col-span-1">
                  <label className="block text-gray-700 font-bold mb-2 uppercase" htmlFor="Soil">
                    SOIL TYPE
                  </label>
                  <select
                    id="Soil"
                    name="Soil"
                    className="w-full p-4 border border-gray-400 rounded bg-gray-100"
                    value={formData.Soil}
                    onChange={handleChange}
                  >
                    <option value="0">Black</option>
                    <option value="1" selected>Clayey</option>
                    <option value="2">Loamy</option>
                    <option value="3">Red</option>
                    <option value="4">Sandy</option>
                  </select>
                </div>

                {/* Submit Button */}
                <div className="col-span-2 flex justify-center mt-6">
                  <button className="bg-gray-800 text-white py-4 px-24 rounded-lg hover:bg-gray-700 mt-4 font-bold text-lg">
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              response && (
                <div>
                  <div className="text-black text-xl font-bold text-center mt-4">
                    Recommended Fertilizer: {response.fertilizer_name}
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={response.fertilizer_image_url} alt="Fertilizer Image" className="w-64 h-64" />
                  </div>
                  <div className="text-black text-lg mt-4">
                    <h2 className="text-xl font-bold">Explanation:</h2>
                    <p>{response.explanation}</p>
                  </div>
                  <div className="text-black text-lg mt-4">
                    <h2 className="text-xl font-bold">How to use:</h2>
                    <p>{response.application_instructions}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {/* Lower Section - bg-gray-100 */}
      <div className="bg-gray-100 flex-1"></div>
    </div>
  );
};

export default FertilizerRecommendation;